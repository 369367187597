import { collapseDrawer, expandDrawer } from '../utils/animation';

const selectors = {
    accordion: '[data-accordion]',
    item: '[data-accordion-item]',
    iconCaret: '[data-accordion-icon-caret]',
    header: '[data-accordion-header]',
    toggle: '[data-accordion-toggle]',
    toggleIcon: '[data-accordion-toggle-icon]',
    toggleLabel: '[data-accordion-toggle-label]',
    content: '[data-accordion-content]',
};

const classes = {
    booted: 'accordion--booted',
    itemOpen: 'accordion__item--open',
};

class AccordionItem {
    constructor(el) {
        this.$el = el;
        this.$iconCaret = el.querySelector(selectors.iconCaret);
        this.$header = el.querySelector(selectors.header);
        this.$toggle = el.querySelector(selectors.toggle);
        this.$toggleIcon = el.querySelector(selectors.toggleIcon);
        this.$toggleLabel = el.querySelector(selectors.toggleLabel);
        this.$content = el.querySelector(selectors.content);
        this.$header.addEventListener('click', () => this.toggle());

        document.addEventListener('accordion-item.open', e => {
            if (e.detail.target === this.$el) {
                this.open();
            }
        });

        this.updateIcon();
    }

    get isOpen() {
        return this.$el.classList.contains(classes.itemOpen);
    }

    toggle() {
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    open() {
        if (this.$content.dataset.reviewsContent == 'true') {
            this.$content.querySelector('.yotpo-main-widget').style.display =
                'block';
        }
        this.$el.classList.add(classes.itemOpen);
        this.$content.setAttribute('aria-expanded', 'true');
        expandDrawer(this.$content);

        this.updateIcon();

        if (!this.$el.classList.contains('accordion__reviews')) {
            if (!this.isInView() && this.$el.scrollIntoView) {
                this.$el.scrollIntoView(false);
            }
        }

        this.dispatchEvent(
            new CustomEvent('accordion-item.opened', {
                target: this,
            })
        );
    }

    close() {
        if (this.$content.dataset.reviewsContent == 'true') {
            this.$content.querySelector('.yotpo-main-widget').style.display =
                'none';
        }
        this.$el.classList.remove(classes.itemOpen);
        if (this.$content.getAttribute('aria-expanded') == 'true') {
            this.$content.setAttribute('aria-expanded', 'false');
            collapseDrawer(this.$content);
        }

        this.updateIcon();

        this.dispatchEvent(
            new CustomEvent('accordion-item.closed', {
                target: this,
            })
        );
    }

    isInView() {
        const bounding = this.$el.getBoundingClientRect();

        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <=
                (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <=
                (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    updateIcon() {
        if (this.$iconCaret) {
            if (this.isOpen) {
                this.$toggleIcon.classList.remove('expand-icon--closed');
            } else {
                this.$toggleIcon.classList.add('expand-icon--closed');
            }
        } else {
            this.$toggleIcon.innerHTML = this.isOpen ? '-' : '+';
        }

        this.$toggleLabel.innerHTML = this.isOpen
            ? window.translations.accordion.collapse
            : window.translations.accordion.expand;
    }

    dispatchEvent(event) {
        this.$el.dispatchEvent(event);
    }
}

export default AccordionItem;
